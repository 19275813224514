import styled from "styled-components";

export const Wrapper = styled.section`
  text-align: center;
`;

export const Title = styled.h2`
  font-weight: 700;
  font-size: 1.25rem;
  color: #272d4e;
  margin-bottom: 2rem;
`;
export const SectionTitle = styled.h4`
  font-weight: 400;
  font-size: 1rem;
  color: #8c8f9f;
  margin: 0;
`;
export const SectionContent = styled.div`
  max-height: calc(100vh - 11rem);
  overflow-y: auto;
`;

export const PrimaryText = styled.div`
  font-weight: 400;
  font-size: 0.875rem;
  color: #272d4e;
`;

export const SubTitle = styled.div`
  font-weight: 400;
  font-size: 0.875rem;
  color: #8c8f9f;
`;

export const SecondaryText = styled.div`
  font-size: 0.875rem;
  font-weight: 500;
  color: #8c8f9f;
`;
export const CapWrapper = styled.div`
  display: flex;
  padding: 1rem;
  gap: 1rem;
  align-items: center;
`;
