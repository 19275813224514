import React from "react";
import { Container, Content } from "./styles";
import SideBar from "./SideBar";
import Header from "./Header";

const LayoutDefault = ({ children }) => {
  return (
    <Container>
      <SideBar />
      <Content>
        <Header />
        {children}
      </Content>
    </Container>
  );
};

export default LayoutDefault;
