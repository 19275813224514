import { createSlice } from '@reduxjs/toolkit'

export const slice = createSlice({
  name: 'manualEntries',
  initialState: {
    entries: [],
    selectedFile: []
  },
  reducers: {
    setEntries: (state, action) => {
      state.entries = action.payload
    },
    setSeletedFile: (state, action) => {
      state.selectedFile = action.payload
    }
  }
})

export const { setEntries, setSeletedFile } = slice.actions
export default slice.reducer
