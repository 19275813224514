import { Spin, Tooltip } from "antd";
import { Link } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";
import NoEntriesIcon from "@/assets/icons/NoEntriesIcon";
import Entry from "./Entries";
import InputSearch from "@/components/InputSearch";
import { Wrapper, SectionTitle, SectionContent, CapWrapper } from "./styles";
const SearchResults = ({ data, isLoading }) => {
  // if (isLoading) {
  //   return (
  //     <Wrapper>
  //       <Spin />
  //     </Wrapper>
  //   );
  // }
  // if (!data.length) {
  //   return (
  //     <Wrapper>
  //       <NoEntriesIcon />
  //     </Wrapper>
  //   );
  // }
  return (
    <section>
      <div>
        <CapWrapper>
          <Link to="/">
            <Tooltip title={"Back"}>
              <ArrowLeftOutlined
                style={{ fontSize: "1.5rem", cursor: "pointer" }}
              />
            </Tooltip>
          </Link>
          <SectionTitle>Survey entries</SectionTitle>
          <InputSearch />
        </CapWrapper>
        <SectionContent>
          {isLoading ? (
            <Wrapper>
              <Spin />
            </Wrapper>
          ) : (
            <>
              {!data.length ? (
                <Wrapper>
                  <NoEntriesIcon />
                </Wrapper>
              ) : (
                <>
                  {data.map((entry) => (
                    <Entry data={entry} key={entry.id} />
                  ))}
                </>
              )}
            </>
          )}
        </SectionContent>
      </div>
    </section>
  );
};

export default SearchResults;
