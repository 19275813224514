import { PrimaryText, SubTitle, SecondaryText } from "../styles";
import { Wrapper } from "./styles";
import { Badge } from "antd";
const Entry = ({ data }) => {
  const {
    surveyName,
    surveyCode,
    projectName,
    projectCode,
    name,
    email,
    amountUsd,
    amountLocal,
    status,
    country,
    currency,
    purpose,
    payment,
  } = data;

  const statuses = ["Unverified", "Approved", "Waiting-for-payment", "Pending"];

  return (
    <Wrapper>
      <div>
        <PrimaryText>{name}</PrimaryText>
        <SubTitle>{email}</SubTitle>
      </div>

      <div>
        <PrimaryText>{surveyName}</PrimaryText>
        <SecondaryText>{surveyCode}</SecondaryText>
      </div>

      <div>
        <PrimaryText>{projectName}</PrimaryText>
        <SecondaryText>{projectCode || "N/A"}</SecondaryText>
      </div>
      <div>
        <PrimaryText>{"Country"}</PrimaryText>
        <SecondaryText>{country}</SecondaryText>
      </div>
      <div>
        <PrimaryText>{"Amount USD"}</PrimaryText>
        <SecondaryText>{amountUsd}</SecondaryText>
      </div>
      <div>
        <PrimaryText>{"Amount LOCAL"}</PrimaryText>
        <SecondaryText>{amountLocal}</SecondaryText>
      </div>
      <div>
        <PrimaryText>{"Detail"}</PrimaryText>

        <SecondaryText>
          {statuses.includes(status) ? (
            <>
              <Badge count={"Unpaid"} color="#FF3333" />
              <Badge count={status} color="#FF3333" />
            </>
          ) : (
            <Badge count={"Paid"} color="#52c41a" />
          )}
          <Badge count={payment} color="#faad14" />
          <Badge count={purpose} color="#89c3c4" />
        </SecondaryText>
      </div>
    </Wrapper>
  );
};

export default Entry;
