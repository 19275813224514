import { createSlice } from '@reduxjs/toolkit'
import moment from 'moment'

const newStart = moment().subtract(3, 'month')
const newEnd = moment()

export const slice = createSlice({
  name: 'query',
  initialState: {
    startDate: newStart.format('YYYY-MM-DD'),
    endDate: newEnd.format('YYYY-MM-DD'),
    country: [],
    surveyType: [],
    amountToBePaidMin: 0,
    amountToBePaidMax: 0,
    amountPaidMin: 0,
    amountPaidMax: 0,
    pageUnpaidSum: 0,
    paymentMethod: [],
    client: [],
    project: [],
    option: '3m',
    paypal: true,
    mobile: true,
    giftcard: true,
    manualEntries: 'include',
    calender: [newStart, newEnd],
    purpose: 'All',
    status: 'All',
    verified: [],
    dueDates: [],
    searchKey: ''
  },
  reducers: {
    setCountry: (state, action) => {
      state.country = action.payload
    },
    setSurvey: (state, action) => {
      state.surveyType = action.payload
    },
    setClient: (state, action) => {
      state.client = action.payload
    },
    setProject: (state, action) => {
      state.project = action.payload
    },
    setOption: (state, action) => {
      state.option = action.payload
    },
    setStartDate: (state, action) => {
      state.startDate = action.payload
    },
    setCalender: (state, action) => {
      state.calender = action.payload
    },
    setEndDate: (state, action) => {
      state.endDate = action.payload
    },
    setPaypal: (state, action) => {
      state.paypal = action.payload
    },
    setMobile: (state, action) => {
      state.mobile = action.payload
    },
    setGiftCard: (state, action) => {
      state.giftcard = action.payload
    },
    setAmountToBePaidMin: (state, action) => {
      state.amountToBePaidMin = action.payload
    },
    setAmountToBePaidMax: (state, action) => {
      state.amountToBePaidMax = action.payload
    },
    setAmountPaidMin: (state, action) => {
      state.amountPaidMin = action.payload
    },
    setAmountPaidMax: (state, action) => {
      state.amountPaidMax = action.payload
    },
    setManualEntry: (state, action) => {
      state.manualEntries = action.payload
    },
    setQuery: (state, action) => {
      const {
        country,
        endDate,
        giftcard,
        mobile,
        option,
        paypal,
        startDate,
        surveyType,
        client,
        project,
        amountToBePaidMin,
        amountToBePaidMax,
        amountPaidMin,
        amountPaidMax,
        searchKey,
        status,
        verified,
        purpose
      } = action.payload
      state.option = option
      state.startDate = startDate
      state.endDate = endDate
      state.country = country
      state.amountToBePaidMin = amountToBePaidMin
      state.amountToBePaidMax = amountToBePaidMax
      state.amountPaidMin = amountPaidMin
      state.amountPaidMax = amountPaidMax
      state.giftcard = giftcard
      state.mobile = mobile
      state.paypal = paypal
      state.surveyType = surveyType
      state.client = client
      state.project = project
      state.searchKey = searchKey
      state.status = status
      state.verified = verified
      state.purpose = purpose
    },
    setPurpose: (state, action) => {
      state.purpose = action.payload
    },
    setStatus: (state, action) => {
      state.status = action.payload
    },
    setVerified: (state, action) => {
      state.verified = action.payload
    },
    setDueDate: (state, action) => {
      state.dueDates = action.payload
    },
    setSearchKey: (state, action) => {
      state.searchKey = action.searchKey
    }
  }
})

export const {
  setCountry,
  setSurvey,
  setClient,
  setProject,
  setOption,
  setStartDate,
  setEndDate,
  setCalender,
  setPaypal,
  setMobile,
  setGiftCard,
  setAmountToBePaidMin,
  setAmountToBePaidMax,
  setAmountPaidMin,
  setAmountPaidMax,
  setManualEntry,
  setQuery,
  setPurpose,
  setStatus,
  setVerified,
  setDueDate,
  setSearchKey
} = slice.actions
export default slice.reducer
