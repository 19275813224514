import { useDispatch } from 'react-redux'
import {
  setAuthenticatedUser,
  removeAuthenticatedUser,
  setUserEmail,
  setIsAdmin as storeSetIsAdmin
} from '@/store/authentication'

const useAuthentication = () => {
  const dispatch = useDispatch()

  const methods = {
    setUserToken: (token) => {
      dispatch(setAuthenticatedUser(token))
    },
    setUserEmailData: (data) => {
      dispatch(setUserEmail(data))
    },
    setIsAdmin: (data) => {
      dispatch(storeSetIsAdmin(data))
    },
    remove: () => {
      dispatch(removeAuthenticatedUser())
    }
  }

  return methods
}

export default useAuthentication
