import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import SearchResults from "@/components/SearchResults";
import { findEntriesByEmail } from "@/apis";

const Container = () => {
  const location = useLocation();
  const [results, setResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleFetchData = async (key) => {
    setIsLoading(true);
    const surveysData = await findEntriesByEmail(key);
    setIsLoading(false);

    setResults(surveysData?.data);
  };

  useEffect(() => {
    const search = new URLSearchParams(location.search);
    const key = search.get("key");

    if (key) {
      handleFetchData(key);
    }
  }, [location]);

  return <SearchResults data={results} isLoading={isLoading} />;
};

export default Container;
