import React from 'react'
import { ThemeProvider } from 'styled-components'
import { DEFAULT_THEME } from '@/utils/constants'
import { GlobalStyle } from './styles'


const Theme = ({ children }) => {
  
  return (
    <ThemeProvider theme={DEFAULT_THEME}>
      {children}
      
      <GlobalStyle theme={DEFAULT_THEME} />
    </ThemeProvider>
  )
}

export default Theme
