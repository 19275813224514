import React from 'react'
import { useSelector } from 'react-redux'
import { Route, Redirect, useHistory } from 'react-router-dom'
import { ONLY_REPORT_VIEW_ACCOUNTS } from '@/utils/constants/constants'

const RoutePrivate = ({ children, ...props }) => {
  const { authenticatedUser } = useSelector(state => state.authentication)
  const { path } = props
  const history = useHistory()
  const pathChecker = children => {
    if (
      path !== '/report' &&
      !ONLY_REPORT_VIEW_ACCOUNTS.includes(authenticatedUser.email)
    ) {
      return children
    } else if (
      ONLY_REPORT_VIEW_ACCOUNTS.includes(authenticatedUser.email) &&
      path === '/report'
    ) {
      return children
    } else if (
      ONLY_REPORT_VIEW_ACCOUNTS.includes(authenticatedUser.email) &&
      path !== '/report'
    ) {
      history.replace('/report')
    } else {
      return children
    }
  }
  return (
    <Route
      {...props}
      render={() =>
        authenticatedUser ? (
          pathChecker(children)
        ) : (
          <Redirect
            to={{
              pathname: '/'
            }}
          />
        )
      }
    />
  )
}

export default RoutePrivate
