import { POST, GET } from "@/utils/axios";

export const paymentList = async (formData, page, limit) =>
  POST(`survey/payment?page=${page}&size=${limit}`, formData, {
    useAuthorization: true,
  });

export const searchPaymentList = async (
  searchedData,
  appliedFilters,
  page,
  limit
) =>
  POST(
    `/survey/list/entries/search/${searchedData}?page=${page}&size=${limit}`,
    appliedFilters,
    {
      useAuthorization: true,
    }
  );

export const surveyList = async (data) =>
  POST("/survey/list/client", data, {
    useAuthorization: true,
  });

export const clientList = async () =>
  GET("/client", {
    useAuthorization: true,
  });

export const projectList = async (client) =>
  POST("/project/client", client, {
    useAuthorization: true,
  });

export const getProducts = async (payload) =>
  POST(
    `/survey/products/${payload.survey}`,
    { isScreener: payload.isScreener },
    {
      useAuthorization: true,
    }
  );

export const getReferrals = async (payload) =>
  POST(
    `/survey/referrals/${payload.survey}`,
    { isScreener: payload.isScreener },
    {
      useAuthorization: true,
    }
  );

export const surveyEnrollments = async (data) =>
  POST("/survey/enrollments", data, {
    useAuthorization: true,
  });

export const surveyEnrollmentsSearch = async (search, data) =>
  POST(`/survey/enrollments/users/search/${search}`, data, {
    useAuthorization: true,
  });

export const surveyEnrollmentsUsers = async (data) =>
  POST("/survey/enrollments/users", data, {
    useAuthorization: true,
  });

export const refreshEntries = async () =>
  GET("/survey-entries/entries", {
    useAuthorization: true,
  });

export const submitManualEntry = async (data) =>
  POST("/manual-payment-entry", data, {
    useAuthorization: true,
  });

export const getAllProjects = () =>
  GET("/project", {
    useAuthorization: true,
  });

export const getProjectSurveys = async (data) =>
  POST("/survey/project-surveys", data, {
    useAuthorization: true,
  });

export const uploadPaypalIncentives = async (data, id) =>
  POST(`/survey/upload-paypal-incentives?survey=${id}`, data, {
    useAuthorization: true,
  });

export const uploadGiftCardIncentives = async (data, id) =>
  POST(`/survey/upload-giftcard-incentives?survey=${id}`, data, {
    useAuthorization: true,
  });

export const uploadBitrefillIncentives = async (data, id) =>
  POST(`/survey/upload-mobile-incentives?survey=${id}`, data, {
    useAuthorization: true,
  });

export const uploadShares = async (data, id) =>
  POST(`/survey/update-shares-status?survey=${id}`, data, {
    useAuthorization: true,
  });

export const getAllVendors = () =>
  GET("/vendors", {
    useAuthorization: true,
  });

export const markEntryAsPaid = async (data) =>
  POST("/survey/entries/update-manual-entries", data, {
    useAuthorization: true,
  });

export const getBulkPaymentList = async (isProtected, purpose) =>
  GET(
    `/survey-entries/payement-incentives-shares-country?isProtectedView=${isProtected}&purpose=${purpose}`,
    {
      useAuthorization: true,
    }
  );

export const getCountryMethodIncentives = async (method, counry) =>
  GET(
    `survey-incentives/payement-incentives?method=${method}&country=${counry}`,
    {
      useAuthorization: true,
    }
  );

export const getCountryMethodShares = async (method, counry) =>
  GET(`survey-shares/sharing-incentives?method=${method}&country=${counry}`, {
    useAuthorization: true,
  });

export const uploadCountryMethodIncentives = async (data) =>
  POST("/survey-incentives/paypal-incentive-country", data, {
    useAuthorization: true,
  });

export const uploadCountryMethodShares = async (data) =>
  POST("/survey-shares/survey-shares-country", data, {
    useAuthorization: true,
  });

export const getProtectedSurveyIncentivesInfo = async (data) =>
  POST(`/survey-entries/get-protected-survey-incentives-info`, data, {
    useAuthorization: true,
  });

export const deleteManualEntry = async (entryId) =>
  POST(
    `/manual-payment-entry/delete/${entryId}`,
    {},
    {
      useAuthorization: true,
    }
  );

export const flavorProjects = async () =>
  GET("/project/list", {
    useAuthorization: true,
  });

export const flavorSurveys = async () =>
  GET("/survey/list", {
    useAuthorization: true,
  });

export const flavorVendors = async () =>
  GET("/vendors/list", {
    useAuthorization: true,
  });

export const resendApprovalEmail = async () =>
  GET("/survey-entries/resend-approval-email", {
    useAuthorization: true,
  });

export const findEntriesByEmail = async (key) => {
  const response = await GET(`/survey-entries/entries-by-email/search`, {
    useAuthorization: true,
    params: { key },
  });

  return response.data;
};
