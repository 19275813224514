import { createSlice } from '@reduxjs/toolkit'

export const slice = createSlice({
  name: 'payments',
  initialState: {
    paymentList: [],
    paymentPagination: { limit: 50, page: 1, total: 0, totalEntries: 0 },
    newRecordCount: {
      all: 0,
      paid: 0,
      unpaid: 0,
      inProgress: 0
    },
    surveylist: [],
    clientList: [],
    projectList: [],
    publish: false,
    suspended: false,
    flavorProjectsList: [],
    flavorSurveysList: [],
    flavorVendorsList: []
  },
  reducers: {
    setPaymentList: (state, action) => {
      state.paymentList = action.payload
    },
    setPaymentPagination: (state, action) => {
      state.paymentPagination = action.payload
    },
    setSurveyList: (state, action) => {
      state.surveylist = action.payload
    },
    setClientList: (state, action) => {
      state.clientList = action.payload
    },
    setProjectData: (state, action) => {
      state.projectList = action.payload
    },
    setPublish: (state, action) => {
      state.publish = action.payload
    },
    setSuspended: (state, action) => {
      state.suspended = action.payload
    },
    setProjects: (state, action) => {
      state.flavorProjectsList = action.payload
    },
    setSurveys: (state, action) => {
      state.flavorSurveysList = action.payload
    },
    setVendors: (state, action) => {
      state.flavorVendorsList = action.payload
    },
    setNewRecordCount: (state, action) => {
      state.newRecordCount = action.payload
    }
  }
})

export const {
  setPaymentList,
  setPaymentPagination,
  setSurveyList,
  setClientList,
  setProjectData,
  setPublish,
  setSuspended,
  setProjects,
  setSurveys,
  setVendors,
  setNewRecordCount
} = slice.actions
export default slice.reducer
