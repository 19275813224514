import { Suspense, lazy } from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import "./App.less";
import "antd/dist/antd.css";
import "./i18n";
import Theme from "@/containers/Theme";
import RoutePublic from "@/containers/RoutePublic";
import RoutePrivate from "@/containers/RoutePrivate";

import Loading from "./pages/Loading";
import Page404 from "./pages/404";
import GlobalSearch from "@/pages/GlobalSearch";
const Welcome = lazy(() => import("@/pages/Welcome"));
const Payments = lazy(() => import("@/pages/PaymentsScreen"));
const UploadStatement = lazy(() => import("@/pages/UploadStatement"));
const AddManualPaymentEntry = lazy(() =>
  import("@/pages/AddManualEntryScreen")
);
const AddBulkPayment = lazy(() => import("@/pages/AddBulkPaymentScreen"));
const PaymentReport = lazy(() => import("@/pages/PaymentReport"));
const PaymentQnA = lazy(() => import("@/pages/PaymentQnA"));
const PaymentDashboard = lazy(() => import("@/pages/PaymentDashboard"));
const EditManualEntry = lazy(() => import("@/pages/EditManualEntry"));

function App() {
  return (
    <Router>
      <Theme>
        <Suspense fallback={<Loading />}>
          <Switch>
            <RoutePublic exact path="/">
              <Welcome />
            </RoutePublic>
            <RoutePrivate exact path="/payments">
              <Payments />
            </RoutePrivate>
            {/* <RoutePrivate exact path="/uploadStatement">
              <UploadStatement />
            </RoutePrivate> */}
            <RoutePrivate exact path="/payments/AddManualEntries">
              <AddManualPaymentEntry />
            </RoutePrivate>
            <RoutePrivate exact path="/payments/AddBulkPayment">
              <AddBulkPayment />
            </RoutePrivate>
            <RoutePrivate exact path="/payments/EditManualEntry">
              <EditManualEntry />
            </RoutePrivate>
            <RoutePrivate exact path="/report">
              <PaymentReport />
            </RoutePrivate>
            <RoutePrivate exact path="/dashboard">
              <PaymentDashboard />
            </RoutePrivate>
            <RoutePrivate exact path="/qna">
              <PaymentQnA />
            </RoutePrivate>
            <RoutePrivate path="/search">
              <GlobalSearch />
            </RoutePrivate>
            <RoutePrivate exact path="/*">
              <Page404 />
            </RoutePrivate>
          </Switch>
        </Suspense>
      </Theme>
    </Router>
  );
}

export default App;
