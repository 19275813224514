import React from 'react'
import Profile from './Profile'
import src from '@/assets/images/dashboard-logo.png'
import { Container, Logo } from './styles'

const Header = () => {
  return (
    <Container>
      <Logo src={src} />
      <Profile />
    </Container>
  )
}

export default Header
