import { createSlice } from '@reduxjs/toolkit'
import jwt_decode from 'jwt-decode'
import cache from '@/utils/cache'

let cacheAuthenticatedUser = null
let userEmail = ''
let userIsAdmin = false

const checkCacheAuthenticatedUser = () => {
  try {
    const token = cache.getItem('flavorwiki-payment-token')
    cacheAuthenticatedUser = token ? jwt_decode(token) : null
  } catch (error) {
    console.error(error)
  }
}

const checkCacheUserEmail = () => {
  //check cache of email and isAdmin
  try {
    const email = cache.getItem('flavorwiki-payment-user-email')
    const isAdmin = cache.getItem('fw-isAdmin') === 'true'

    userEmail = email ? email : ''
    userIsAdmin = isAdmin ? isAdmin : false
  } catch (error) {
    console.error(error)
  }
}

checkCacheAuthenticatedUser()
checkCacheUserEmail()

export const slice = createSlice({
  name: 'authentication',
  initialState: {
    isAdminView: false,
    authenticatedUser: cacheAuthenticatedUser,
    userEmail: userEmail,
    isAdmin: userIsAdmin
  },
  reducers: {
    setAuthenticatedUser: (state, action) => {
      cache.setItem('flavorwiki-payment-token', action.payload)

      const authenticatedUser = jwt_decode(action.payload)
      state.authenticatedUser = authenticatedUser
    },
    removeAuthenticatedUser: (state, action) => {
      cache.removeItem('flavorwiki-payment-token')
      cache.removeItem('fw-isAdmin')
      state.authenticatedUser = null
      state.isAdmin = false
    },
    setUserEmail: (state, action) => {
      cache.setItem('flavorwiki-payment-user-email', action.payload)

      state.userEmail = action.payload
    },
    setIsAdmin: (state, action) => {
      cache.setItem('fw-isAdmin', action.payload)
      state.isAdmin = action.payload
    }
  }
})

export const {
  setAuthenticatedUser,
  removeAuthenticatedUser,
  setUserEmail,
  setIsAdmin
} = slice.actions

export default slice.reducer
