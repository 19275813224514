import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Dropdown } from 'antd'
import useAuthentication from '@/hooks/useAuthentication'
import { logUserSnap } from '@/utils/userSnap'
import { DownOutlined } from '@ant-design/icons'
import { Email } from './styles'

const Profile = () => {
  const { t } = useTranslation()
  const authentication = useAuthentication()
  const userEmail = useSelector((state) => state.authentication.userEmail)
  const { authenticatedUser } = useSelector((state) => state.authentication)

  useEffect(() => {
    if (authenticatedUser) {
      logUserSnap()
    }
  }, [])

  const handleLogout = () => {
    authentication.remove()
  }

  const itemsMenu = [
    {
      key: 'close',
      label: t('components.logout')
    }
  ]

  return (
    <Dropdown
      placement="bottom"
      menu={{ items: itemsMenu, onClick: handleLogout }}
      trigger={['click']}
    >
      <Email>
        {userEmail}
        <DownOutlined style={{ fontSize: '0.75rem' }} />
      </Email>
    </Dropdown>
  )
}
export default Profile
