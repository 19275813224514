export const FILTER_OPTIONS = ["7d", "All", "Custom"];
export const BG_COLOR_ACTIVE = "#FFFFFF";
export const BG_COLOR_DEACTIVE = "#F7FAFE";

export const TEXT_COLOR_ACTIVE = "#367f98";
export const TEXT_COLOR_DEACTIVE = "#94a2b3";
export const DEFAULT_FILTER_OPTION = "All";
export const CUSTOM_FILTER_OPTION = "Custom";
export const PAID = "Paid";
export const UNPAID = "Unpaid";
export const BATCH = "waiting-for-payment";
export const NOT_AVAILABLE = "N/A";
export const PAYPAL = "paypal";
export const GIFTCARD = "giftcard";
export const ALIPAY = "alipay";
export const BITREFILL = "bitrefill";
export const MOBILE = "mobile";
export const WOGI = "wogi";
export const OVERDUE = "Overdue";
export const TREMENDOUS = "tremendous";
export const FAILED_PAYMENT = "failed-payments";

export const NOTIFICATION_COLOR = "#FFF8D8";

export const ENTRY_TYPE = "Manual Entry";

export const THREE_MONTHS = "3m";
export const UNAUTHORIZED = "Unauthorized";
export const SETPAYPALDATA = "setPaypalData";
export const SETMOBILEDATA = "setMobileData";
export const SETGIFTCARDDATA = "setGiftCardData";
export const PURPOSE = "purpose";
export const PROTECTED_PURPOSE = "protectedPurpose";
export const INCENTIVES = "Incentives";
export const SHARES = "Shares";

export const PAGES_SIZE = [
  { key: "50", value: 50 },
  { key: "100", value: 100 },
  { key: "150", value: 150 },
  { key: "200", value: 200 },
];

export const ACTIONS_MANUAL_ENTRY = {
  ADD: "add",
  EDIT: "edit",
};

export const CSV_DATA = [
  [
    "purpose",
    "project",
    "projectId",
    "survey",
    "surveyId",
    "country",
    "email",
    "vendor",
    "currency",
    "comment",
    "payment",
    "amountTobePaid",
  ],
  [
    "Marketing",
    "Test project",
    "1123123123",
    "Test Survey",
    "12312345",
    "US",
    "test@gmail.com",
    "testing vendor",
    "AED",
    "some comment",
    "paypal",
    "20",
  ],
];

export const KEYS_OBJECT = {
  purpose: "Purpose",
  project: "Project",
  survey: "Survey",
  country: "Country",
  vendor: "Vendor",
  email: "Email",
  userName: "Username",
  currency: "Currency",
  comment: "Comment",
  date: "Date",
  payment: "Payment",
  phoneNumber: "Phone Number",
  amountTobePaid: "Amount (Local)",
  amountTobePaidUSD: "Amount (USD)",
};

export const FORM_INITIAL_STATE = {
  purpose: null,
  project: null,
  projectId: null,
  survey: null,
  surveyId: null,
  country: null,
  vendor: null,
  email: null,
  userName: null,
  currency: "USD",
  comment: null,
  date: null,
  payment: null,
  phoneNumber: null,
  option: "giftcard",
  amountTobePaid: null,
  amountTobePaidUSD: null,
  manualEntryId: null,
  entryId: null,
};

export const PURPOSES = [
  {
    value: "Incentives",
    label: "Incentives",
  },
  // {
  //   value: 'Marketing',
  //   label: 'Marketing'
  // },
  {
    value: "Shares",
    label: "Shares",
  },
  // {
  //   value: 'Copywriter',
  //   label: 'Copywriter'
  // },
  // {
  //   value: 'Deposit',
  //   label: 'Deposit'
  // },
  // {
  //   value: 'Expense - Hotel',
  //   label: 'Expense - Hotel'
  // },
  // {
  //   value: 'Expense - Meals',
  //   label: 'Expense - Meals'
  // },
  // {
  //   value: 'Expense - Parking',
  //   label: 'Expense - Parking'
  // },
  // {
  //   value: 'Expense - Plane',
  //   label: 'Expense - Plane'
  // },
  // {
  //   value: 'Expense - Taxi/Transport',
  //   label: 'Expense - Taxi/Transport'
  // },
  // {
  //   value: 'Expense -Telephone',
  //   label: 'Expense -Telephone'
  // },
  // {
  //   value: 'Fee',
  //   label: 'Fee'
  // },
  // {
  //   value: 'IDI Summary',
  //   label: 'IDI Summary'
  // },
  // {
  //   value: 'Moderator',
  //   label: 'Moderator'
  // },
  // {
  //   value: 'Project Assistant',
  //   label: 'Project Assistant'
  // },
  // {
  //   value: 'Project Services',
  //   label: 'Project Services'
  // },
  // {
  //   value: 'Project Supplies',
  //   label: 'Project Supplies'
  // },
  // {
  //   value: 'Recruiting',
  //   label: 'Recruiting'
  // },
  // {
  //   value: 'Refund',
  //   label: 'Refund'
  // },
  // {
  //   value: 'Report',
  //   label: 'Report'
  // },
  // {
  //   value: 'Shipment',
  //   label: 'Shipment'
  // },
  // {
  //   value: 'Text Review',
  //   label: 'Text Review'
  // },
  // {
  //   value: 'Translator',
  //   label: 'Translator'
  // },
  // {
  //   value: 'User Interviews',
  //   label: 'User Interviews'
  // },
  // {
  //   value: 'User Review',
  //   label: 'User Review'
  // }
];

export const PURPOSES_DROPDOWN = [
  "Incentives",

  // 'Marketing',

  "Shares",

  // 'Copywriter',

  // 'Deposit',

  // 'Expense Hotel',

  // 'Expense Meals',

  // 'Expense Parking',

  // 'Expense Plane',

  // 'Expense Transport',

  // 'Expense Telephone',

  // 'Fee',

  // 'IDI Summary',

  // 'Moderator',

  // 'Project Assistant',

  // 'Project Services',

  // 'Recruiting',

  // 'Refund',

  // 'Report',

  // 'Shipment',

  // 'Text Review',

  // 'Translator',

  // 'User Interviews',

  // 'User Review'
];

export const EXCEL_HEADERS = [
  "purpose",
  "project",
  "survey",
  "vendor",
  "payment",
  "country",
  "currency",
  "email",
  "userName",
  "amountTobePaid",
  "comment",
];

export const PAYMENT_DROPDOWN = [
  "paypal",
  "giftcard",
  "bitrefill",
  // 'ribbon',
  // 'wogi',
  // 'wedoogift',
  // 'bank transfer'
];

export const ONLY_REPORT_VIEW_ACCOUNTS = ["priscilla@flavorwiki.com"];

export const HOT_FILTERS = ["Unverified", "Pending", "Approved"];
