import styled, { css } from 'styled-components'

export const Container = styled.div`
  width: 4rem;
  box-shadow: inset -1px 0px 0px #e5eaf4;
  background-color: white;
`

export const IconWrapper = styled.div`
  top: 0;
  position: sticky;

  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 1rem;

  padding-top: 1rem;
`

export const Icon = styled.div`
  width: 2.5rem;
  height: 2.5rem;

  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0.25rem 0.5rem rgba(0, 0, 0, 0.04);
  border-radius: 0.5rem;
  cursor: pointer;

  background: ${(props) =>
    props.clicked ? props.theme.PRIMARY_COLOR : 'white'};
  color: ${(props) => (props.clicked ? 'white' : '')};

  &:focus,
  &:hover {
    border: 1px solid ${(props) => props.theme.PRIMARY_COLOR};
  }
`
