import styled from 'styled-components'

export const Container = styled.header`
  top: 0;
  position: sticky;
  height: 4rem;

  display: flex;
  align-items: center;
  justify-content: space-between;

  background: ${(props) => props.theme.WHITE};
  padding: 0 2rem;
  margin-bottom: 2rem;

  z-index: 1000;
`

export const Logo = styled.img`
  width: 132.22px;
  height: 20px;
  cursor: pointer;
`

export const MenuWrapper = styled.div`
  display: flex;
  flex: 1;
  margin-left: 2rem;
`
