import { configureStore } from '@reduxjs/toolkit'

import logger from 'redux-logger' //Adding redux logger

import reducer from './combinedReducers'

const devMiddleWares = []

if (process.env.NODE_ENV === `development`) {
  devMiddleWares.push(logger)
}

export default configureStore({
  reducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore these action types
        ignoredActions: [
          'query/setStartDate',
          'query/setEndDate',
          'query/setCalender'
        ],
        // Ignore these field paths in all actions
        ignoredActionPaths: [
          'payload.startDate',
          'payload.endDate',
          'payload.calender'
        ],
        // Ignore these paths in the state
        ignoredPaths: ['query.startDate', 'query.endDate', 'query.calender']
      }
    }).concat(devMiddleWares)
})
