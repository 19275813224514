import React from 'react'
import Spin from '@/components/UIKits/Spin'
import { Screen } from './styles'

const Loading = () => (
  <Screen>
    <Spin size="large" />
  </Screen>
)

export default Loading
