import React from 'react'
import { Result } from 'antd'
import LayoutDefault from '@/layouts/Default'

const page404 = () => {
  return (
    <LayoutDefault>
      <Result
        status="404"
        title="404"
        subTitle="Sorry, the page you visited does not exist."
        style={{ height: 'calc(100vh - 6rem)' }}
      />
    </LayoutDefault>
  )
}

export default page404
