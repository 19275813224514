export const DEFAULT_THEME = {
  NAME: 'flavorwiki',
  APP_TITLE: 'FlavorWiki Payment Application',
  ICON_URL: 'https://tools.flavorwiki.com/default.png',
  FULL_LOGO_URL:
    'https://taster-flavorwiki.s3.eu-central-1.amazonaws.com/FlavorWiki_Full_Logo.png',
  ALLOW_SOCIAL_NETWORK: true,
  ALLOW_TASTER: true,
  ALLOW_SHARE: true,
  TOKEN_HOURS: 24,
  PRIMARY_COLOR: '#367F98',
  FACEBOOK: '#4267B2',
  TWITTER: '#1f9bf0',
  SLATE_GREY: '#4e4e56',
  PALE_LIGHT_GREY: '#e4e4e5',
  LIGHT_GREY: '#cdcdd2',
  CHART_LABEL: '#7f7f7f',
  VERY_LIGHT_GREY: '#f0f0f0',
  ERROR: '#f5222d',
  WHITE: '#ffffff',
  BLACK: '#000000',
  LIGHT_BLUE: '#1890ff',
  SALMON: '#df625f',
  DARK_BACKGROUND: '#fafafa',
  ADMIN_CONTENT_BACKGROUND: '#f0f2f5',
  RED: '#e90000',
  DARKER_RED: '#d10000',
  BLUE_GREEN: '#147a73',
  SHARE: '#1a73e8',
  CARD_BORDER: '#eeeeee',
  SOCIAL_TEXT_COLOR: '#73738d',
  SOCIAL_BUTTON_COLOR: '#eaedf3',
  STATS_GREY_COLOR: '#8c8f9f',
  RHINO_COLOR: '#272d4e',
  SELAGO_COLOR: '#f7fafe',
  CERISE_RED_COLOR: '#da2f58',
  ALTO_COLOR: '#e0e0e0',
  CLOUD_BURST_COLOR: '#202945',
  DARK_SELAGO_COLOR: '#e6effb',
  BRICK_RED_COLOR: '#CE272C',
  THUNDER_COLOR: '#231f20',
  EASTERN_BLUE_COLOR: '#1e9bbb',
  CATSKILL_WHITE_COLOR: '#e5eaf4',
  ASTRONAUT: '#28356A',
  CURIOUS_BLUE_COLOR: '#298FC2',
  FOAM_COLOR: '#f0f9fd',
  OXFORD_BLUE_COLOR: '#3a425f',
  SILVER_COLOR: '#cccccc',
  FOUNTAIN_BLUE_COLOR: '#4ab9bb',
  GRAY_COLOR: '#8c8c8c',
  LAVENDER_BLUSH_COLOR: '#fff7fa',
  SCANDEL_COLOR: '#e1fcef',
  JEWEL_COLOR: '#14804a',
  SANTAS_GREY_COLOR: '#a3a6b9',
  DARK_FOUNTAIN_BLUE_COLOR: '#4ea3bf',
  ROCK_BLUE_COLOR: '#a5b2cb',
  ZIRCON_COLOR: '#f8fbff',
  ZUMTHOR_COLOR: '#eaf2ff',
  GULL_GREY_COLOR: '#94A2B3',
  SUNSET_ORANGE_COLOR: '#ff4d4f'
}
