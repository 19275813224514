import styled from 'styled-components'

export const Container = styled.div`
  display: grid;
  grid-template-columns: 4rem 1fr;
`

export const Content = styled.div`
  background-image: linear-gradient(#f8fbff, #eaf2ff);
  flex-basis: 100%;
`
