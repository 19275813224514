import { combineReducers } from 'redux'
import authenticationReducer from './authentication'
import userReducer from './users'
import paymentReducers from './queries'
import paymentResponseReducer from './paymentResponse'
import manualEntriesReducer from './addManualEntriesQuery'

const reducer = combineReducers({
  authentication: authenticationReducer,
  user: userReducer,
  query: paymentReducers,
  payment: paymentResponseReducer,
  manualEntries: manualEntriesReducer
})

export default reducer
