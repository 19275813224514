import { Link, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Tooltip, notification, message } from 'antd'
import { Container, IconWrapper, Icon } from './styles'
import { DollarOutlined, ProfileOutlined } from '@ant-design/icons'
import { ONLY_REPORT_VIEW_ACCOUNTS } from '@/utils/constants/constants'

const Sidebar = () => {
  const location = useLocation()
  const { entries } = useSelector(state => state.manualEntries)
  const user = useSelector(state => state.authentication)

  const onClickDisable = () => {
    notification.destroy()
    message.error('Please complete creating manual entry or cancel it!')
  }

  let menu = ONLY_REPORT_VIEW_ACCOUNTS.includes(user.userEmail.toLowerCase())
    ? [
        {
          title: 'Payment Report',
          link: '/report',
          path: 'report',
          icon: <ProfileOutlined />
        }
      ]
    : [
        {
          title: 'Payment Approval',
          link: '/payments',
          path: 'payments',
          icon: <DollarOutlined />
        },
        {
          title: 'Payment Report',
          link: '/report',
          path: 'report',
          icon: <ProfileOutlined />
        }
        // Add other menu items here if needed
      ]

  return (
    <Container>
      <IconWrapper>
        {menu.map((item, index) => (
          <Tooltip title={item.title} placement='right' key={index}>
            {entries?.length > 0 ? (
              <Icon
                clicked={location.pathname.includes(item.path)}
                onClick={onClickDisable}
              >
                {item.icon}
              </Icon>
            ) : (
              <Link to={item.link}>
                <Icon
                  clicked={location.pathname.includes(item.path)}
                  onClick={() => notification.destroy()}
                >
                  {item.icon}
                </Icon>
              </Link>
            )}
          </Tooltip>
        ))}
      </IconWrapper>
    </Container>
  )
}
export default Sidebar
